import styled from '@emotion/styled'

export const BottomImageCardContainer = styled.div`
  display: flex;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;

  @media (min-width: 1001px) {
    justify-content: space-around;
    top: -200px;
    margin-bottom: -100px;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
`