import React from "react";
import styled from '@emotion/styled'


const BottomImageCardDiv = styled.div`
  padding: 30px 10px;
  margin: 20px 20px;
  border-radius: 10px;
  text-align: center;

  @media (min-width: 1001px) {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    background: white;
    width: 300px;
    height: 300px;
    overflow: hidden;
  }
  @media (max-width: 1000px) {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 90%;
    :nth-of-type(odd) {
      flex-direction: row-reverse;
    }
  }
`;

export const BottomImageCard = (props) => <BottomImageCardDiv>
  <div css={{}}>
    <h2 css={{ fontSize: "22px", textAlign: "center", fontWeight: "bolder" }}>{props.title}</h2>
    <div css={{ maxWidth: "250px", margin: "0 auto" }}>
      <p css={{ fontSize: "14px", textAlign: "left" }}>{props.description}</p>
    </div>
  </div>
  <img alt="" src={props.img} className="d-none d-md-block" css={{ position: "relative", '@media (min-width: 1001px)': {
    margin: "20px auto 0"
   }}} />
</BottomImageCardDiv>