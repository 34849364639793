import React from "react"
import { Link } from "gatsby";
import '../styles/global.css';
import { TitleHeader } from "../components/title-header/titleHeader";
import { CrowdCallContainer } from "../components/global/global";
import { TiltedAccentTitle } from "../components/tilted-title-accent/tilted-title-accent";
import { Button } from "react-bootstrap";
import { ContactFormWrapper } from "../components/contact/contactFormWrapper";
import { Footer } from "../components/footer/footer";
import { BottomImageCard } from "../components/bottom-image-card/bottomImageCard";
import { BottomImageCardContainer } from "../components/bottom-image-card/bottomImageCardContainer";
import { PriceDescription } from "../components/price-description/priceDescription";
import { PriceCalculator } from "../components/price-calculator/priceCalculator";

export default () => <CrowdCallContainer>
  <TitleHeader title="Prices that work with any budget" subtitle="Only pay for what you use, with no contracts, volume requirements, or monthly fees." />
  <BottomImageCardContainer>
    <BottomImageCard title="Unlimited Volunteers" description="With CrowdCall, you can have unlimited volunteers calling at the same time for no charge." img="/images/volunteer-select.svg" />
    <BottomImageCard title="Pay As You Go" description="You will never have to sign a contract or make a commitment to use CrowdCall. You only pay for what you use." img="/images/save-money-no-bg.svg" />
    <BottomImageCard title="Free Support" description="Your success is our success. We offer free unlimited one-on-one support to all campaigns for setup or troubleshooting." img="/images/support.svg" />
  </BottomImageCardContainer>

  <div css={{ maxWidth: "800px", width: "80%", margin: "20px auto 0" }}>
    <h1>Prices</h1>
    <PriceDescription title="Outbound Calling" description="Phonebanks make almost exclusively outbound calls, so this will be the majority of your cost. Our prices are the same no matter who you’re calling." price={<span>0.9¢ <span css={{ fontSize: "12px" }}>($0.009)</span></span>} priceUnit="per minute" />
    <PriceDescription title="Inbound Calling" description="Inbound calling minutes are only charged when a voter calls back. This will be a very small part of your bill – our average user has around 4 minutes of callbacks for every 100 minutes of phonebanking." price={<span>1.5¢ <span css={{ fontSize: "12px" }}>($0.015)</span></span>} priceUnit="per minute" />
    <PriceDescription title="Calling Identity" description="Each calling identity is one phone number (any area code) that you are able to make and recieve phone calls from. You can have unlimited volunteers making calls from the same phone number." price="$1" priceUnit="per calling identity" />
    <br />
    <PriceCalculator />
  </div>

  <TiltedAccentTitle
    title="Affordable by design"
    subtitle="We started CrowdCall after working with too many campaigns that were paying too much for their phones. To this day, we remain as a wholly mission-driven organization. We don’t see our users as a revenue source — we see them as potential new voices in office. This mission-driven mindset means that charge far less than anyone else.">
    <Link to="/mission"><Button variant="light">Learn More</Button></Link>
  </TiltedAccentTitle>

  <ContactFormWrapper />

  <Footer />

</CrowdCallContainer>
